import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import chunk from 'lodash/chunk';

import Layout from '../components/layout/layout';
import HeaderExpansion from '../components/layout/header-expansion';
import Section from '../components/layout/section';
import FlexboxItem from '../components/layout/flexbox/flexbox--item';
import FlexboxTwoPart from '../components/layout/flexbox/flexbox--two-part';
import FlexboxThreePart from '../components/layout/flexbox/flexbox--three-part';

import { FixString } from '../functions/FixString';
import { GenerateSEO } from '../functions/GenerateSEO';

const Services = ({ data: { allContentfulGrampusGServices: { nodes: services } } }) => {

    const section2SubSections = chunk(services[0].section2.sections, 4);
    const section4Subsections = chunk(services[0].section4.sections, 3);

    return (
        <Layout 
            contactBanner={ true }
            location="services"
            metadata={ GenerateSEO({
                is404: false,
                lang: 'en',
                title: services[0].seo.seoTitle,
                description: services[0].seo.seoDescription,
                image: services[0].Images[0].localFile.childImageSharp.fixed.src,
                imageAlt: services[0].Images[0].description,
                url: 'https://www.grampusg.com/services/'
            })}>
            <HeaderExpansion tagline={ services[0].tagline } />
            { /* <Section className="first">
                <FlexboxTwoPart>
                    <FlexboxItem>
                        { services[0].section1.header ? <h2 className="section__header">{ services[0].section1.header }</h2> : '' }
                        {
                            services[0].section1.sections.map((paragraph, index) => (
                                <React.Fragment key={ index }>
                                    { paragraph.header ? <h3 className="section__subheader">{ paragraph.header }</h3> : '' }
                                    { paragraph.content.content ? <p className="section__paragraph add-margin">{ FixString(paragraph.content.content) }</p> : '' }
                                </React.Fragment>
                            ))
                        }
                    </FlexboxItem>
                    <FlexboxItem isImage>
                        <Image className="section__image" fluid={ services[0].Images[0].localFile.childImageSharp.fluid } alt={ services[0].Images[0].description } />
                    </FlexboxItem>
                </FlexboxTwoPart>
                <FlexboxTwoPart className="section__flexbox-parent--four-by-four">
                    <FlexboxItem isImage>
                        <Image className="section__image" fluid={ services[0].Images[1].localFile.childImageSharp.fluid } alt={ services[0].Images[1].description } />
                    </FlexboxItem>
                    <FlexboxItem>
                        { services[0].section2.header ? <h2 className="section__header">{ services[0].section2.header }</h2> : '' }
                        {
                            section2SubSections.map((itemContainer, index) => (
                                <React.Fragment key={ index }>
                                    <FlexboxTwoPart>
                                        { 
                                            itemContainer.filter((item, index) => index < 2).map((item, index) => (
                                                <FlexboxItem key={ index }>
                                                    { item.header ? <h3 className="section__subheader">{ item.header }</h3> : '' }
                                                    { item.content.content ? <p className="section__paragraph">{ FixString(item.content.content) }</p>: '' }
                                                </FlexboxItem>
                                            ))
                                        }
                                    </FlexboxTwoPart>
                                    <FlexboxTwoPart>
                                        {
                                            itemContainer.filter((item, index) => index > 1).map((item, index) => (
                                                <FlexboxItem key={ index + 2 }>
                                                    { item.header ? <h3 className="section__subheader">{ item.header }</h3> : '' }
                                                    { item.content.content ? <p className="section__paragraph">{ FixString(item.content.content) }</p> : '' }
                                                </FlexboxItem>
                                            ))
                                        }
                                    </FlexboxTwoPart>
                                </React.Fragment>
                            ))
                        }
                    </FlexboxItem>
                </FlexboxTwoPart>
            </Section> */ }
            <Section className="first blue-background">
                {/*<FlexboxTwoPart>
                    <FlexboxItem>
                        { services[0].section3.header ? <h2 className="section__header">{ services[0].section3.header }</h2> : '' }
                        {
                            services[0].section3.sections.map((paragraph, index) => (
                                <React.Fragment key={ index }>
                                    { paragraph.header ? <h3 className="section__subheader">{ paragraph.header }</h3> : '' }
                                    { paragraph.content.content ? <p className="section__paragraph">{ FixString(paragraph.content.content) }</p> : '' }
                                </React.Fragment>
                            ))
                        }
                    </FlexboxItem>
                    <FlexboxItem isImage>
                        <Image className="section__image" fluid={ services[0].Images[2].localFile.childImageSharp.fluid } alt={ services[0].Images[2].description } />
                    </FlexboxItem>
                    </FlexboxTwoPart>*/}
                <section className="services__image">
                    <div className="services__image-text">
                        { services[0].section3.header ? <h2 className="section__header">{ services[0].section3.header }</h2> : '' }
                        {
                            services[0].section3.sections.map((paragraph, index) => (
                                <React.Fragment key={ index }>
                                    { paragraph.header ? <h3 className="section__subheader">{ paragraph.header }</h3> : '' }
                                    { paragraph.content.content ? <p className="section__paragraph">{ FixString(paragraph.content.content) }</p> : '' }
                                </React.Fragment>
                            ))
                        }
                    </div>
                    <FlexboxItem isImage>
                        <Image className="section__image" fluid={ services[0].Images[2].localFile.childImageSharp.fluid } alt={ services[0].Images[2].description } />
                    </FlexboxItem>
                </section>
                { services[0].section4.header ? <h2 className="section__header">{ services[0].section4.header }</h2> : '' }
                {
                    section4Subsections.map((itemContainer, outerIndex) => (
                        <FlexboxThreePart className="add-margin" key={ outerIndex }>
                            {
                                itemContainer.map((item, innerIndex) => (
                                    <FlexboxItem key={ innerIndex }>
                                        { item.header ? <h3 className="section__subheader">{ item.header }</h3> : '' }
                                        { item.content.content ? <p className="section__paragraph">{ FixString(item.content.content) }</p> : '' }
                                    </FlexboxItem>
                                ))
                            }
                        </FlexboxThreePart>
                    ))
                }
            </Section>
      </Layout>
  );
}

export const query = graphql`
query ServicesQuery {
  allContentfulGrampusGServices {
    nodes {
      seo {
        seoTitle
        seoDescription
      }
      Images {
        fixed {
          src
        }
        localFile {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
                fixed {
                    src
                }
            }
        }
        description
      }
      tagline
      section1 {
        header
        sections {
          header
          content {
            content
          }
        }
      }
      section2 {
        header
        sections {
          header
          content {
            content
          }
        }
      }
      section3 {
        header
        sections {
          header
          content {
            content
          }
        }
      }
      section4 {
        header
        sections {
          header
          content {
            content
          }
        }
      }
    }
  }
}
`;

export default Services;